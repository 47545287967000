<template>
  <div :style="$attrs.style" class="overflow-auto w-100 mt-3">
    <div class="row py-type no-gutters mb-1" v-for="attachment in attachments">
      <div class="col-5">
        {{attachment.name}}.{{attachment.extension}}
      </div>
      <div class="col-2">
        {{attachment.created_at}}
      </div>
      <div class="col-2">
        {{attachment.file_size}}
      </div>
      <div class="col-3">
        <template v-if="attachment.mime_type.includes('image')">
          <button class="btn btn-secondary btn-xs mr-2"
                  type="button"
                  v-if="isAllowed(viewPermission) && !editAttachment"
                  @click="viewDownloadFile(attachment,type)"
                  :disabled="!attachment.has_file">
            View
          </button>
          <button class="btn btn-secondary btn-xs mr-2"
                  type="button"
                  v-if="isAllowed(downloadPermssion) && isOwnVessel('pscReport') && !editAttachment"
                  @click="viewDownloadFile(attachment,type,'download')"
                  :disabled="!attachment.has_file">
            Download
          </button>
        </template>
        <template v-else>
          <button
                 type="button"
                 class="btn btn-secondary btn-xs mr-2" :disabled="!attachment.has_file"
                  @click="viewDownloadFile(attachment,type) && !editAttachment"
                  v-if="isAllowed(viewPermission)"
          >
            View / Download
          </button>
        </template>
        <button class="btn btn-danger btn-xs"
                type="button"
                @click="$emit('deleteEmitter',{attachment:attachment,type:type})"
                v-if="isAllowed(deletePermission) && isOwnVessel('pscReport') && editAttachment"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AttachmentMixin from "../../../mixins/attachmentMixin";
import PscDeficiencyMixin from "../../../mixins/PscDeficiencyMixin";
import {permissionMixin} from "../../../mixins/permissionMixins";

export default {
  mixins:[AttachmentMixin,PscDeficiencyMixin,permissionMixin],
  props:{
    type:{
      type:Number,
      default:null,
    },
    viewPermission:{
      type:String,
      default:'',
    },
    downloadPermssion:{
      type:String,
      default:'',
    },
    deletePermission:{
      type:String,
      default:''
    },
    attachments:{
      type:[Object,Array],
      default:null,
    },
    toEditOccasionalSurvey : {
      type:[Boolean],
      default:false
    }
    // toEdit:{
    //   type:[Boolean],
    //   default:false
    // }
  },
  computed: {
    editAttachment() {
      return this.toEdit || this.toEditOccasionalSurvey;
    }
  }

}
</script>

<style scoped>

</style>
