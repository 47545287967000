<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="updatePsc">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              Occasional Survey
            </h5>
          </div>
          <div class="col-auto"
               v-if="isAllowed('psc.main.occasional_survey.edit') && isOwnVessel('pscReport')">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelUpdate" v-if="toEdit">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
            </template>
            <template v-else>
              <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="editMainReport">Edit Occasional Survey</button>
            </template>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td style="width: 25%">Recognized Organization (RO)</td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.occasional_survey.recognized_organization" class="form-control-sm form-control">
              <span v-else>{{ pscGet('occasional_survey.recognized_organization') }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">RO Office</td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.occasional_survey.ro_office" class="form-control-sm form-control">
              <span v-else>{{ pscGet('occasional_survey.ro_office') }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Date of Occasional Survey</td>
            <td>
              <input type="date" min="1975-01-01" v-if="toEdit" v-model="mainReport.occasional_survey.occasional_survey_date" class="form-control form-control-sm w-auto mb-2">
              <span v-else>{{ pscGet('occasional_survey.occasional_survey_date') | moment('D MMM YYYY') }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Place of Survey</td>
          </tr>
          <tr>
            <td style="width: 25%" class>
              <div class="ml-3">
                Country
              </div>
            </td>
            <td>
              <select v-if="toEdit" class="form-control form-control-sm text-uppercase" v-model="mainReport.occasional_survey.survey_country_id">
                <option :value="null">—— Select Country ——</option>
                <option v-for="country in countryKeys" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
              <span v-else>{{ pscGet('occasional_survey.country.name') }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              <div class="ml-3">
                Port
              </div>
            </td>
            <td>
              <select v-if="toEdit" class="form-control form-control-sm text-uppercase" v-model="mainReport.occasional_survey.survey_port_id" :disabled="!ports.length">
                <option :value="null">—— Select Port ——</option>
                <option v-for="port in ports" :key="port.id" :value="port.id">
                  {{ port.name }}
                </option>
              </select>
              <span v-else>{{ pscGet('occasional_survey.port.name') }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <hr>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Attached RO Report
              <template v-if="toEdit">
                <button type="button" class="e-btn btn-xs e-btn-green ml-2 px-5 e-text-white"
                        v-if="isAllowed('psc.main.occasional_survey.attachments.create') && isOwnVessel('pscReport')"
                        data-toggle="modal" data-target="#occasionalSurveyAttachments">
                  <font-awesome-icon class="mr-1" icon="plus"/>
                  Add Attachment
                </button>
              </template>
            </td>
            <td>
              <div class="row">
                <div class="col-12 py-2 text-right" v-if="toEdit">
                  <button type="button" class="e-btn btn-xs e-btn-blue ml-2 px-5 e-text-white"
                          v-if="isAllowed('psc.main.occasional_survey.attachments.download-all') && isOwnVessel('pscReport') && occasionalSurveyAttachments.length"
                          @click="downloadAll(6,pscReport,'occasional_survey.id')">
                    <font-awesome-icon class="mr-1" icon="download"/>
                    Download All Documents
                  </button>
                </div>
                <div class="col-12">
                  <psc-attachment :attachments="occasionalSurveyAttachments"
                                  :to-edit-occasional-survey="toEdit"
                                  style="height: 15vh;"
                                  :type="attachmentType"
                                  view-permission="psc.main.occasional_survey.attachments.view-download"
                                  download-permssion="psc.main.occasional_survey.attachments.view-download"
                                  delete-permission="psc.main.occasional_survey.attachments.delete"
                                  @deleteEmitter="deleteOccasionalSurveyAttachment"
                  >

                  </psc-attachment>

                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <hr>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Remark</td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.occasional_survey.remark" class="form-control-sm form-control">
              <span v-else>{{ pscGet('occasional_survey.remark') }}</span>
            </td>
          </tr>
        </table>
      </form>

      <div class="modal fade" id="occasionalSurveyAttachments" tabindex="-1">
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title">Add Attachments</h6>
              <button type="button" class="close"  ref="closeOccasionalSurveyAttachments" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <input type="file" ref="occasionalSurveyAttachments" @change="addOccasionalSurveyAttachments" name="attachments" id="attachments" multiple accept=".pdf">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "../../../mixins/ObjectMixin";
import {AlertService} from "../../../services/AlertService";
import AttachmentMixin from "../../../mixins/attachmentMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import PscAttachment from "./PscAttachment.vue";

export default {
  name: "PscOccasionalSurvey",
  data() {
    return {
      toEdit: false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
      attachmentType : 6,
      mainReport: {}
    }
  },
  mixins: [ObjectMixin,AttachmentMixin,permissionMixin],
  components:{PscAttachment},
  methods: {
    ...mapActions(['updatePscInfo','uploadPscAttachments']),
    async cancelUpdate(){
      if(await AlertService.cancelAlert()){
        this.toEdit = false
      }
    },
    async updatePsc() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        const response = await this.updatePscInfo(this.mainReport);
        if (response) {
          AlertService.successAlert('PSC updated successfully', 'UPDATE PSC')
          this.toEdit = false
        }
      }
    },
    async setMainReport() {
      this.mainReport = {
        id: this.pscGet('id'),
        occasional_survey: {
          id : this.pscGet('occasional_survey.id'),
          psc_main_id: this.pscGet('id'),
          recognized_organization: this.pscGet('occasional_survey.recognized_organization'),
          ro_office: this.pscGet('occasional_survey.ro_office'),
          occasional_survey_date: this.pscGet('occasional_survey.occasional_survey_date'),
          survey_country_id: this.pscGet('occasional_survey.survey_country_id'),
          survey_port_id: this.pscGet('occasional_survey.survey_port_id'),
          remark: this.pscGet('occasional_survey.remark'),
        }
      }
    },
    editMainReport() {
      this.toEdit = true;
      this.setMainReport();
    },
    isPdfFile(fileName) {
      return fileName.split('.')[fileName.split('.').length - 1] === 'pdf';
    },
    async addOccasionalSurveyAttachments() {
      swal.fire({title: 'Uploading Occasional Survey Pdf file... Please wait'});
      swal.showLoading();
      let hasNotPdf = false;
      Array.prototype.forEach.call(this.$refs.occasionalSurveyAttachments.files,file => {
        let isPdfFile = this.isPdfFile(file.name);
        if (isPdfFile === false) {
          hasNotPdf = true;
        }
      });
      this.$refs.closeOccasionalSurveyAttachments.click();
      if (hasNotPdf === true) {
        swal.close();
        this.$refs.occasionalSurveyAttachments.value = null;
        return AlertService.errorAlert('One of the selected file is not pdf.', 'UPLOADING OCCASIONAL SURVEY ATTACHMENT');
      }

      if (this.pscReport.occasional_survey === null) {
        const updatePscResponse = await this.updatePscInfo({
          id: this.pscGet('id'),
          occasional_survey: {
            id: 0,
            psc_main_id: this.pscGet('id'),
            recognized_organization: null,
            ro_office: null,
            occasional_survey_date: null,
            survey_country_id: null,
            survey_port_id: null,
            remark: null,
          },
        });

        if (updatePscResponse) {
          this.mainReport.occasional_survey.id = this.pscGet('occasional_survey.id');
        }
      }

      let formData = new FormData();
      Array.prototype.forEach.call(this.$refs.occasionalSurveyAttachments.files,file => {
        formData.append('attachments[]',file)
      });

      formData.append('id',this.pscGet('id'));
      formData.append('type[id]',this.pscGet('occasional_survey.id'));
      formData.append('type[type_id]',6);

      const response = await this.uploadPscAttachments(formData);
      this.$refs.occasionalSurveyAttachments.value = null;
      if(response){
        swal.close();
        AlertService.successAlert('Attachment added successfully','Add Occasional Survey Attachments');
        await this.getAttachments(6, this.pscReport, 'occasional_survey.id');
      }
    },
    async deleteOccasionalSurveyAttachment(attachment){
      const file = attachment.attachment
      const type = this.attachmentType
      const response = await this.deleteFilePsc(file,type)
      if(response){
        this.getAttachments(6, this.pscReport, 'occasional_survey.id');
        AlertService.successAlert('Psc Corrective Attachment deleted successfully','DELETE CORRECTIVE ATTACHMENT')
      }
    }
  },
  async created() {
    this.$store.commit('EMPTY_OCCASIONAL_SURVEY_ATTACHMENTS');
    if (this.pscReport.occasional_survey !== null) {
      await this.getAttachments(6, this.pscReport, 'occasional_survey.id');
    }
  },
  computed: {
    ...mapGetters([
      'pscReport',
      'countryKeys',
      'portKeys',
      'occasionalSurveyAttachments',
    ]),
    ports () {
      if (this.mainReport.occasional_survey === undefined) {
        return [];
      }
      this.mainReport.occasional_survey.survey_port_id = null;
      return this.portKeys.filter(port => port.country_id === this.mainReport.occasional_survey.survey_country_id);
    }
  },

}
</script>

<style scoped>
table td {
  padding: 2px !important;
}
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
</style>
